import { AccountBox, Call, Email, Facebook, KeyboardDoubleArrowRight, LinkedIn, Twitter } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material'
import { textAlign } from '@mui/system';
import React from 'react';
import { NavLink } from 'react-router-dom';
import cbell from './cbell.png';
import "./Footer.css";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
  return (
    <Grid container bgcolor="#273773" color="white" padding={'2%'}>
      <Grid item lg={1}/>
      <Grid  item lg={2} xs={12} >
        <NavLink to={'/'} style={{ textDecoration: 'none' }}>
        <img className='lo' src={cbell} alt="" style={{marginTop:'20%', width: '75%'}}/>
        </NavLink>
        <Typography className='title' variant="h6" sx={{fontWeight:'700' , fontFamily:'Roboto',marginLeft:'4%'}}>Crestbell Support Private Limited</Typography>
      </Grid>

      <Grid container item lg={2} sx={{fontFamily:"Roboto", mt:'1%', display:'block',textAlign:'justify',ml:'4%'}}>
        <Grid item sx={{display:'flex',pb:'2%'}}>
          <Typography sx={{fontWeight:500, fontSize:'24px',pb:'2%'}}>Quick Links</Typography>
        </Grid>
        <Grid item sx={{display:'flex'}}>
          <Grid item ><KeyboardDoubleArrowRight sx={{marginTop:'14%',color:'#62f4b9'}}/></Grid>
          <NavLink to='/About' style={{ textDecoration: 'none' }}><Typography variant="h6" sx={{pb:'2%', color: 'white'}}>About Us</Typography></NavLink>
        </Grid>
        {/* <Grid item sx={{display:'flex'}}>
          <Grid item><KeyboardDoubleArrowRight sx={{marginTop:'14%',color:'#62f4b9'}}/></Grid>
          <NavLink to='/Support-Center' style={{ textDecoration: 'none' }}><Typography variant="h6" sx={{pb:'2%', color: 'white'}}>Become an ALSC</Typography></NavLink>
        </Grid> */}
        <Grid item sx={{display:'flex'}}>
          <Grid item><KeyboardDoubleArrowRight sx={{marginTop:'14%',color:'#62f4b9'}}/></Grid>
          <NavLink to="/Partnered-organisation" style={{ textDecoration: 'none' }}><Typography variant="h6" sx={{pb:'2%', color: 'white'}}>Become a <br/> Partnered Organisation</Typography></NavLink>
        </Grid>
        <Grid item sx={{display:'flex'}}>
          <Grid item><KeyboardDoubleArrowRight sx={{marginTop:'14%',color:'#62f4b9'}}/></Grid>
          <NavLink to='/Keewe-solutions' style={{ textDecoration: 'none' }}><Typography variant="h6" sx={{pb:'2%', color: 'white'}}>KEEWE Solutions</Typography></NavLink>
        </Grid>
        <Grid item sx={{display:'flex'}}>
          <Grid item><KeyboardDoubleArrowRight sx={{marginTop:'14%',color:'#62f4b9'}}/></Grid>
          <NavLink to= '/Privacy' style={{ textDecoration: 'none'}}>
          <Typography variant="h6" sx={{pb:'2%', color: 'white'}}>Privacy-Policy</Typography></NavLink>
        </Grid>
        <Grid item sx={{display:'flex'}}>
          <Grid item><KeyboardDoubleArrowRight sx={{marginTop:'14%',color:'#62f4b9'}}/></Grid>
          <NavLink to= '/APtable' style={{ textDecoration: 'none'}}>
          <Typography variant="h6" sx={{pb:'2%', color: 'white', textAlign:"left"}}>Partnered Organisation</Typography></NavLink>
        </Grid>
      </Grid>

      <Grid container item lg={2} sx={{fontFamily:"Roboto", mt:'1%', display:'block',textAlign:'justify',ml:'2%'}}>
        <Grid item><Typography sx={{fontWeight:500, fontSize:'24px',pb:'2%'}}>Contact Us</Typography></Grid>
        <Grid item sx={{display:'flex',pb:'2%',mt:'4%'}}>
          <Grid item sx={{mr:'2%'}}><Call sx={{color:'#62f4b9'}}/></Grid>
          <Typography fontSize="18px" sx={{fontFamily:'Roboto',fontWeight:'500'}}>+91-8295554696</Typography> 
        </Grid>
        <Grid item sx={{display:'flex',pb:'2%',mt:'4%'}}>
          <Grid item sx={{mr:'2%'}}><Email sx={{color:'#62f4b9'}}/></Grid>
          <Typography fontSize="18px" sx={{fontFamily:'Roboto',fontWeight:'500'}}>info@crestbellsupport.com</Typography>
        </Grid>
        <Grid item sx={{display:'flex',pb:'2%',mt:'4%'}}>
          <Grid item sx={{mr:'2%'}}><AccountBox sx={{color:'#62f4b9'}}/></Grid>
          <Typography fontSize="18px" sx={{fontFamily:'Roboto',fontWeight:'500', textAlign:'left'}}>#SCO 218-219, Sector-34 A 2nd Floor, Chandigarh</Typography>
        </Grid>
      </Grid>

      <Grid container item lg={3} sx={{mt:'1%', display:'block',ml:'4%'}}>
        <Grid item><Typography sx={{fontWeight:500, fontSize:'24px',pb:'2%'}} >Crestbell Support Center</Typography></Grid>
        <Grid item sx={{display:'flex',pb:'2%'}}>
          <Grid item sx={{mr:'2%'}}><Call sx={{color:'#62f4b9'}}/></Grid>
          <Typography fontSize="18px">+91-8295554696</Typography>  
        </Grid>
        <Grid item sx={{display:'flex',pb:'2%'}}>
          <Grid item sx={{mr:'2%'}}><Email sx={{color:'#62f4b9'}}/></Grid>
          <Typography fontSize="18px">supportcenter@crestbellsupport.com</Typography>
        </Grid>
        <Grid item sx={{display:'flex',pb:'2%'}}>
          <Typography variant="h6">Follow Us</Typography>
        </Grid>
        <Grid container marginTop={'2%'} >
          <Grid item ><Facebook sx={{}}/></Grid>
          <Grid item ><Twitter sx={{marginLeft:'66%'}}/></Grid>
          <Grid item><LinkedIn sx={{marginLeft:'130%'}}/></Grid>
        </Grid>
      </Grid>
     
      <Grid item lg={12} xs={12} sx={{textAlign:'center'}}>
        <Typography sx={{fontFamily:"Roboto", fontSize:'16px', mt:'3%', mb:'3%'}}>© 2023 Crestbell. All Rights Reserved.</Typography>
      </Grid>
      
    </Grid>
  )
}

export default Footer
